<template>
  <div class="today-in-histories" v-if="model">
    <div class="tih-background">
      <img src="/images/bg/news/news-bg.png" />
    </div>
    <div class="date-bar">
      <div class="title wow fadeInUp" data-wow-delay="1s">
        <span>{{ day }}</span>
      </div>
      <div class="detail-text wow fadeInUp" data-wow-delay="2s">
        <span>{{ model.description }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import store from "../store/index";
import dateHelper from "../shared/date-helper";
import WOW from "wow.js";
new WOW().init();
export default {
  name: "today-in-history",
  props: ["data"],
  created() {
    this.startTimer();
  },
  data() {
    return {
      destroyed: false
    };
  },
  methods: {
    startTimer() {
      setTimeout(() => {
        if (!this.destroyed) store.dispatch("setNextPlaylist");
      }, 20000);
    }
  },
  computed: {
    model() {
      return this.$store.state.todayInHistory;
    },
    day() {
      return this.model
        ? dateHelper.toInputDateFormat(this.model.eventDate)
        : "";
    }
  },
  beforeDestroy() {
    this.destroyed = true;
  }
};
</script>

<style lang="scss">
@import "../assets/animate/animate.css";

.today-in-histories {
  .tih-background {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .date-bar {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 110px;
    font-family: Montserrat-Regular;
  }

  .date-bar .title {
    width: 692px;
    height: 242px;
    margin: 0 auto;
    position: relative;
    z-index: 55;
    background: url("/images/bg/today-in-history.png") center no-repeat;
    background-size: 100% auto;
  }

  .date-bar .title span {
    display: inline-block;
    position: absolute;
    right: 12px;
    bottom: 106px;
    color: white;
    font-size: 25px;
    letter-spacing: 2px;
    font-weight: bold;
    line-height: 52px;
    width: 322px;
    text-align: center;
  }

  .date-bar .detail-text {
    background: #ad1c33;
    background: rgba(171, 18, 42, 0.85);
    position: relative;
    z-index: 52;
    margin-top: -106px;
    padding: 40px 0 30px;
    color: white;
    font-size: 33px;
    text-align: center;
    border-top: solid 2px white;
    border-bottom: solid 2px white;

    span {
      max-width: 1340px;
      display: block;
      margin: 0 auto;
    }
  }
}</style>
